import RWD002 from "../class/rwd002";
import customDrawerNav from "./drawerNav";
import customFormConfirm from "./formConfirm";
import customSubNav from "./subNav";
import LazyDisp from "../class/lazydisp";

const [w, d] = [window, document];

export default class CUSTOMIZE extends RWD002 {

  constructor(opt) {
    opt = Object.assign({
      // fixNavを指定すると、スクロール時に固定するクラスを付与する
      // fixNav : '[data-role="fixNav"]',
    },opt);

    // 上位クラスのコンストラクタ呼び出し
    super(opt);

    // カスタム用drawerNavのインスタンス化
    this.drawerNav = new customDrawerNav(this.opt.drawerNav);

    // カスタム用サブナビ登録クラスのインスタンス化
    this.Subnav = new customSubNav(this.opt);

    // コメントフォーム用確認画面モーダルのインスタンス化
    this.formConfirm = new customFormConfirm(this.opt.formConfirm);

    this.customInit();
    this.footerSlide();
    this.pageBack();
    this.pageTop();
    this.footVideo();
  }

  customInit() {

    // イベント一覧
    d.addEventListener('rwd002.beforeInit', () => false );
    d.addEventListener('rwd002.beforeDomready', () => false );
    d.addEventListener('rwd002.beforeWindowload', () => false );
    d.addEventListener('rwd002.zip2addr', () => false );
    d.addEventListener('drawerNav.beforeInit', () => false );
    d.addEventListener('collapseNav.beforeInit', () => false );
    d.addEventListener('Scroll.beforeInit', () => false );
    d.addEventListener('Scroll.scrollUp', () => false );
    d.addEventListener('Scroll.scrollDown', () => false );
    d.addEventListener('Lazydisp.beforeInit', () => false );

    // テンプレートインスタンスのプロミス
    this.promises.init.then( () => false );
    this.promises.domReady.then( () => false );
    this.promises.windowLoad.then( () => false );

    // テンプレートインスタンスの全非同期メソッドの完了時に実行
    this.promiseAll().then( () => false );

    // サブナビ登録サンプル
    d.addEventListener('rwd002.beforeDomready', () => {
      this.customSubnav();
    });

    $(() => {
      // htmlデータ読み込み
      $('[data-load-html]').loadHtml();

      // カレントメニュー
      $('[data-plugin=currentMenu]').currentMenu({mode:0,default:999});

      Promise.all([window.load['loadHtml']]).then(() => this.hashscroll());
      Promise.all([window.load['loadHtml']]).then(() => new LazyDisp('[data-lazydisp]'));
    });
  }

  // サブメニュー追加サンプル(beforeDomreadyに登録して使用)
  customSubnav () {
    /**
     * サブナビ追加メソッド(グローバルナビとフッター)
     * ヘッダー・フッター内の[data-key]属性値をもつ要素すべてに対して、サブナビを追加する
     * 第一引数にセレクタを指定(rwd002.opt.gnav / rwd002.opt.fnav )
     * 第一引数にコンテンツ番号を指定(li[data-key])
     * アイテムの出力HTMLは"subnavItemTemplate"によって出力される
     * 第二引数の個別アイテムにHTMLを指定すると、デフォルトのHTMLを上書きする
     * 親要素liに[data-collapse="true"]を指定すると、サブナビを開閉するボタンを追加する
     */

    // header gnav用サブナビ追加
    this.Subnav.add(this.opt.gnav, 'content1', [
      { html: `
        <li class="${this.Subnav.opt.subnavClass}-title">
          <strong>ムガイの技術</strong><span>TECHNOLOGY</span>
        </li>`
      },
      { html: `
        <li class="${this.Subnav.opt.subnavClass}-item">
          <a href="/technology/" class="${this.Subnav.opt.subnavClass}-item__link">
            <p><strong>生産加工</strong><small>PRODUCTION</small></p>
            <img class="arrow" src="/assets/img/common/arrow_wh.svg">
          </a>
        </li>`
      },
      { html: `
        <li class="${this.Subnav.opt.subnavClass}-item">
          <a href="/technology/?mode=detail&article=2" class="${this.Subnav.opt.subnavClass}-item__link">
            <p><strong>品質保証</strong><small>QUALITY</small></p>
            <img class="arrow" src="/assets/img/common/arrow_wh.svg">
          </a>
        </li>`
      },
      { html: `
        <li class="${this.Subnav.opt.subnavClass}-item">
          <a href="/technology/?mode=detail&article=3" class="${this.Subnav.opt.subnavClass}-item__link">
            <p><strong>開発製品</strong><small>DEVELOPMENT</small></p>
            <img class="arrow" src="/assets/img/common/arrow_wh.svg">
          </a>
        </li>`
      },
    ]);

    this.Subnav.add(this.opt.gnav, 'content2', [
      { html: `
        <li class="${this.Subnav.opt.subnavClass}-title">
          <strong>企業情報</strong><span>ABOUT US</span>
        </li>`
      },
      { html: `
        <li class="${this.Subnav.opt.subnavClass}-item">
          <a href="/aboutus/" class="${this.Subnav.opt.subnavClass}-item__link">
            <figure><img class="icon icon_about01" src="/assets/img/common/icon_about01_wh.svg"></figure>
            <span>代表挨拶</span>
            <img class="arrow" src="/assets/img/common/arrow_wh.svg">
          </a>
        </li>`
      },
      { html: `
        <li class="${this.Subnav.opt.subnavClass}-item">
          <a href="/aboutus/?mode=detail&article=2" class="${this.Subnav.opt.subnavClass}-item__link">
            <figure><img class="icon icon_about02" src="/assets/img/common/icon_about02_wh.svg"></figure>
            <span>会社概要</span>
            <img class="arrow" src="/assets/img/common/arrow_wh.svg">
          </a>
        </li>`
      },
      { html: `
        <li class="${this.Subnav.opt.subnavClass}-item">
          <a href="/aboutus/?mode=detail&article=3" class="${this.Subnav.opt.subnavClass}-item__link">
            <figure><img class="icon icon_about03" src="/assets/img/common/icon_about03_wh.svg"></figure>
            <span>環境方針</span>
            <img class="arrow" src="/assets/img/common/arrow_wh.svg">
          </a>
        </li>`
      },
      { html: `
        <li class="${this.Subnav.opt.subnavClass}-item">
          <a href="/aboutus/?mode=detail&article=4" class="${this.Subnav.opt.subnavClass}-item__link">
            <figure><img class="icon icon_about04" src="/assets/img/common/icon_about04_wh.svg"></figure>
            <span>設備紹介</span>
            <img class="arrow" src="/assets/img/common/arrow_wh.svg">
          </a>
        </li>`
      },
    ]);

    this.Subnav.add(this.opt.gnav, 'content4', [
      { html: `
        <li class="${this.Subnav.opt.subnavClass}-title">
          <strong>採用情報</strong><span>RECRUIT</span>
        </li>`
      },
      { html: `
        <li class="${this.Subnav.opt.subnavClass}-item">
          <a href="/recruit/" class="${this.Subnav.opt.subnavClass}-item__link">
            <figure><img class="icon icon_rec01" src="/assets/img/common/icon_rec01_wh.svg"></figure>
            <span>採用情報</span>
            <img class="arrow" src="/assets/img/common/arrow_wh.svg">
          </a>
        </li>`
      },
      { html: `
        <li class="${this.Subnav.opt.subnavClass}-item">
          <a href="/recruit/?mode=detail&article=2" class="${this.Subnav.opt.subnavClass}-item__link">
            <figure><img class="icon icon_rec02" src="/assets/img/common/icon_rec02_wh.svg"></figure>
            <span>先輩メッセージ</span>
            <img class="arrow" src="/assets/img/common/arrow_wh.svg">
          </a>
        </li>`
      },
      { html: `
        <li class="${this.Subnav.opt.subnavClass}-item">
          <a href="/recruit/?mode=detail&article=3" class="${this.Subnav.opt.subnavClass}-item__link">
            <figure><img class="icon icon_rec03" src="/assets/img/common/icon_rec03_wh.svg"></figure>
            <span>数字で見るムガイ</span>
            <img class="arrow" src="/assets/img/common/arrow_wh.svg">
          </a>
        </li>`
      },
      { html: `
        <li class="${this.Subnav.opt.subnavClass}-item">
          <a href="/recruit/#Guideline" class="${this.Subnav.opt.subnavClass}-item__link">
            <figure><img class="icon icon_rec04" src="/assets/img/common/icon_rec04_wh.svg"></figure>
            <span>募集要項</span>
            <img class="arrow" src="/assets/img/common/arrow_wh.svg">
          </a>
        </li>`
      },
    ]);

    // footer nav用サブナビ追加
    this.Subnav.add(this.opt.fnav, 'content1', [
      { label: 'サブメニュー01', href: '#'},
      { label: 'サブメニュー02', href: '#'},
      { label: 'サブメニュー03', href: '#'},
    ]);
  }

  footerSlide () {
    $(window).on('load', function(){
      $('footer .slide_trigger').on('click', function(){
        $(this).toggleClass('open');
        $(this).next('.sub_list').slideToggle();
      });
    });
  }

  pageBack () {
    window.onpageshow = function(event) {
      if (event.persisted) {
         window.location.reload();
      }
    };
  }

  pageTop () {
    $(window).on('load', function () {
      let winH = $(window).height();
      $(window).on('scroll', function () {
        let t = $(this).scrollTop();
        if (t > winH) {
          $('.pagetop').addClass('active');
        } else {
          $('.pagetop').removeClass('active');
        }
      });
    })
  }

  footVideo () {
    w.addEventListener('DOMContentLoaded', function () {
      const fixedVideo = d.getElementById('fixedVideo');
      if ($(this).width() > 899) {
        fixedVideo.innerHTML = '<source src="/assets/img/common/mv.mp4" type="video/mp4">';
      } else {
        fixedVideo.innerHTML = '<source src="/assets/img/common/mv_sp.mp4" type="video/mp4">';
      }
    });
  }

  loading() {
    d.documentElement.classList.add( this.opt.loadingClass );
    d.documentElement.classList.add( 'is-pageload-available' );
    d.addEventListener('DOMContentLoaded', () => {
      d.documentElement.classList.remove( this.opt.loadingClass );
      d.documentElement.style.opacity = '';
    });
  }
  
}

window.WebFontConfig = {
  google: {
    families: ['Roboto:400,500,700,900']
  },
  active: function () {
    sessionStorage.fonts = true;
  }
};

(function () {
  var wf = document.createElement('script');
  wf.src = 'https://ajax.googleapis.com/ajax/libs/webfont/1.6.26/webfont.js';
  wf.type = 'text/javascript';
  wf.async = 'true';
  var s = document.getElementsByTagName('script')[0];
  s.parentNode.insertBefore(wf, s);
})();