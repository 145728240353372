import GET_ARTICLES from "../class/getArticles";

const [ d, w ] = [ document, window ];

const domReady = () => {
  // Promise.all([window.load['recruit']]).then( () => new recruitBLOCK );
  new recruitBLOCK;
};

class recruitBLOCK {
  constructor() {
    this.require();
  }

  require () {
    const is_top = d.body.classList.contains('is--article-1');
    if( is_top ){
      const guideItem = function() {
        const getArticle = new GET_ARTICLES('.require_list');
        getArticle.makeItem = (item) => `
        <li>
          <a href="${item.href}">
            <figure><img src="${item.thumb}" alt="${item.title}"></figure>
            <dl>
              <dt>${item.title}</dt>
              <dd>${item.body.replace(/(<([^>]+)>)/gi, "").str_truncate(150)}</dd>
            </dl>
            <div class="arrow_wrap"><img src="/assets/img/common/arrow.svg"></div>
          </a>
        </li>`;
        getArticle.render();
      }
      setTimeout(() => {
        guideItem();
      }, 500);
    }
  }
}

export default function RECRUIT () {
  // テンプレートが用意したDOMContentLoaded（テンプレートインスタンスが引数に入る）
  // d.addEventListener('rwd002.beforeDomready', e => domReady(e.detail) );
  $(w).on('load', domReady);
}